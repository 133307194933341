import React, { useEffect, useState, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "reactstrap";

import { post } from "../../services/request";
import ReactTable from "../Styled/Table";
import View from "../Styled/View";
/* istanbul ignore next */
const styles = theme => {
	const { type } = theme.palette;
	const dark = type === "dark";
	return {
		hash: {
			"&, & li": {
				overflow: "visible !important"
			}
		},
		partialHash: {
			textAlign: "center",
			position: "relative !important",
			"&:hover $lastFullHash": {
				marginLeft: -400
			},
			"&:hover $fullHash": {
				display: "block",
				position: "absolute !important",
				padding: "4px 4px",
				backgroundColor: dark ? "#5e558e" : "#000000",
				marginTop: -30,
				marginLeft: -215,
				borderRadius: 8,
				color: "#ffffff",
				opacity: dark ? 1 : undefined
			}
		},
		fullHash: {
			display: "none"
		},
		lastFullHash: {},
		filter: {
			width: "100%",
			textAlign: "center",
			margin: "0px !important"
		},
		filterButton: {
			opacity: 0.8,
			margin: "auto",
			width: "100% !important",
			"margin-bottom": "4px"
		},
		searchButton: {
			opacity: 0.8,
			margin: "auto",
			width: "100% !important",
			backgroundColor: dark ? undefined : "#086108",
			"margin-bottom": "4px"
		},
		filterElement: {
			textAlign: "center",
			display: "flex",
			padding: "0px !important",
			"& > div": {
				width: "100% !important",
				marginTop: 20
			},
			"& .label": {
				margin: "25px 10px 0px 10px"
			}
		}
	};
};
function AccountsView(props) {
	const { classes } = props;
	const [pageIndex, setPageIndex] = useState(0); // 当前页
	const [pageSize, setPageSize] = useState(10); // 每页数量
	const [param] = useState({}); // 查询条件
	const [lists, setLists] = useState([]); // 查询结果数据
	const [count, setCount] = useState(0); // 总条数
	const [sorted, setSorted] = useState([{ id: "lastStoreTime", desc: true }]); // 本地排序条件
	// const [sorted, setSorted] = useState([]); // 本地排序条件
	const [loading, setLoading] = useState(false);
	const getLists = useCallback(() => {
		setLoading(true);
		post("https://api.51ssjq.com/external/blockChain/queryCompanyStatistics", {
			pageIndex: pageIndex + 1,
			pageSize,
			param,
			sort: sorted[0] ? (sorted[0].desc ? "desc" : "asc") : "",
			sortField: sorted[0] ? sorted[0].id : ""
		})
			.then(res => {
				if (res.code === 200) {
					setLists(res.data.items);
					setCount(res.data.total);
				}
			})
			.catch(e => console.log(e))
			.finally(() => {
				setLoading(false);
			});
	}, [pageIndex, pageSize, param, sorted]);
	useEffect(() => {
		getLists();
	}, [getLists]);
	const columnHeaders = [
		{
			Header: "序号",
			accessor: "serialNumber",
			// Cell: ({ index }) => {
			// 	return index + 1;
			// },
			sortable: false
		},
		{
			Header: "公司名称",
			accessor: "companyName",
			sortable: false
		},
		{
			Header: obj => {
				console.group("obj");
				console.info(obj);
				console.groupEnd();
				return "存证发票数量";
			},
			accessor: "invoiceCount"
		},
		{
			Header: "存证凭证数量",
			accessor: "voucherCount"
		},
		{
			Header: "存证财务报表数量",
			accessor: "settleCount"
		},
		{
			Header: obj => {
				console.log(obj);
				return "最后存证时间";
			},
			accessor: "lastStoreTime"
		}
	];

	return (
		<div>
			<View>
				<div className={`${classes.filter} row searchRow`}>
					{/* <div className="col-md-2 offset-md-6">
						<Button className={classes.searchButton} color="success">
							Search
						</Button>
					</div> */}
					<div className="col-md-2 offset-md-8">
						<Button
							className={classes.filterButton}
							color="primary"
							onClick={() => {
								setSorted([]);
								setPageIndex(0);
								setPageSize(10);
							}}
						>
							Reset
						</Button>
					</div>
					<div className="col-md-2">
						<Button
							className={classes.filterButton}
							color="secondary"
							onClick={() => {
								setSorted([]);
							}}
						>
							Clear Filter
						</Button>
					</div>
				</div>
				<ReactTable
					sorted={sorted}
					onSortedChange={sorted => {
						setSorted(sorted);
					}}
					data={lists}
					columns={columnHeaders}
					pageSizeOptions={[5, 10, 20, 25, 50, 100, 200, 500]}
					defaultPageSize={pageSize}
					list
					minRows={0}
					style={{ height: "750px" }}
					showPagination={count > pageSize}
					manual
					loading={loading}
					pages={Math.ceil(count / pageSize)}
					onFetchData={state => {
						console.log(state);
					}}
					page={pageIndex}
					onPageChange={index => setPageIndex(index)}
					pageSize={pageSize}
					onPageSizeChange={size => {
						setPageSize(size);
						setPageIndex(0);
					}}
				/>
			</View>
		</div>
	);
}

export default withStyles(styles)(AccountsView);
