/**
 *    SPDX-License-Identifier: Apache-2.0
 */

const namespaces = 'auth';

const LOGIN = `${namespaces}/LOGIN`;

const NETWORK = `${namespaces}/NETWORK`;

const ERROR = `${namespaces}/ERROR`;

const REGISTER = `${namespaces}/REGISTER`;

const UNREGISTER = `${namespaces}/UNREGISTER`;

const USERLIST = `${namespaces}/USERLIST`;

export default {
	LOGIN,
	ERROR,
	NETWORK,
	REGISTER,
	UNREGISTER,
	USERLIST
};
